<template>
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <!-- Kanji title -->
    <div class="learn-row" style="justify-content: left">
      <div class="learn-symbol kanji">
        <div lang="ja">
          {{ card.character }}
        </div>
      </div>
      <div class="learn-key">{{ card.meanings[0] }}</div>
      <div class="learn-alternate-meanings">
        {{ card.meanings.slice(1).join(", ") }}
      </div>
    </div>
  </div>
  <!-- meaning mnemonics -->
  <div>
    <p v-for="mnemonic of card.meaning_mnemonic" v-html="mnemonic"></p>
    <p v-for="mnemonic of card.meaning_mnemonic_hints" v-html="mnemonic"></p>
  </div>
  <!-- readings -->
  <div style="display: flex; justify-content: space-evenly">
    <div>
      <div>音読み</div>
      <div :style="{opacity: card.reading_type==='On’yomi'?1:0.5}">
        <div v-for="reading of card.readings.onyomi">{{ reading }}</div>
      </div>
    </div>
    <div>
      <div>訓読み</div>
      <div :style="{opacity: card.reading_type==='Kun’yomi'?1:0.5}">
        <div v-for="reading of card.readings.kunyomi">{{ reading }}</div>
      </div>
    </div>
  </div>
  <!-- reading mnemonics -->
  <div>
    <p v-for="mnemonic of card.reading_mnemonic" v-html="mnemonic"></p>
    <p v-for="mnemonic of card.reading_mnemonic_hints" v-html="mnemonic"></p>
  </div>
</template>

<script>

export default {
  props: {
    card: Object,
  },
}

</script>

<style lang="scss" scoped>

</style>