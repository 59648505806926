<template>
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <!-- Vocab title -->
    <div class="learn-row" style="justify-content: left">
      <div class="learn-symbol vocab">
        <div lang="ja">
          {{ card.word }}
        </div>
      </div>
      <div class="learn-key">{{ card.meanings[0] }}</div>
      <div class="learn-alternate-meanings">
        {{ card.meanings.slice(1).join(", ") }}
      </div>
    </div>
  </div>
  <!-- Word types -->
  <div style="margin-top: 20px; text-transform: capitalize;">
    {{ card.word_types.join(", ") }}
  </div>
  <!-- meaning mnemonics -->
  <div>
    <p v-for="mnemonic of card.meaning_mnemonic" v-html="mnemonic"></p>
    <p v-for="mnemonic of card.meaning_mnemonic_hints" v-html="mnemonic"></p>
  </div>
  <!-- Pitch diagrams -->
  <div>
    <span v-for="diagram of card.pitch_diagrams" v-html="diagram"></span>
  </div>
  <!-- readings -->
  <div>
    {{ card.reading.filter(r=>r.acceptedAnswer).map(r=>r.reading).join("、　") }}
  </div>
  <!-- Audios -->
  <div>
    <audio controls controlslist="nodownload noplaybackrate" v-for="audio of card.audios.filter(a=>a.contentType==='audio/ogg')" :key="audio.url">
      <source :src="audio.url" :type="audio.contentType">
    </audio>
  </div>
  <!-- reading mnemonics -->
  <div>
    <p v-for="mnemonic of card.reading_mnemonic" v-html="mnemonic"></p>
    <p v-for="mnemonic of card.reading_mnemonic_hints" v-html="mnemonic"></p>
  </div>
  <!-- Patterns of use -->
  <table id="pattern-table">
    <template v-for="(group, groupKey) of patternGroups">
      <tr>
        <th :rowspan="group.length">{{groupKey}}</th>
        <td>{{group[0].japanese}}</td>
        <td>{{group[0].english}}</td>
      </tr>
      <tr v-for="item of group.slice(1)">
        <td>{{item.japanese}}</td>
        <td>{{item.english}}</td>
      </tr>
    </template>
  </table>
  <!-- Sentences -->
  <div style="margin: 40px 0;">
    <div v-for="sentence of card.sentences" style="display: flex; gap: 30px; justify-content: space-between; margin: 10px 0;">
      <div>{{sentence.ja}}</div>
      <div>{{sentence.en}}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    card: Object,
  },
  computed: {
    patternGroups() {
      const groups = {};
      for (const pattern of this.card.patterns??[]) {
        groups[pattern.patternOfUse] = groups[pattern.patternOfUse] ?? [];
        groups[pattern.patternOfUse].push(pattern);
      }
      return groups;
    },
  }
}

</script>

<style lang="scss" scoped>

</style>