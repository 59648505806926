<template>
  <div class="learn-row">
    <div class="learn-symbol radical">
      <img v-if="card.image" :src="card.image">
      <div v-else lang="ja">
        {{ card.character }}
      </div>
    </div>
    <div class="learn-key">{{ card.key }}</div>
  </div>
  <p v-html="card.mnemonic" style="max-width: 500px"></p>
</template>

<script>

export default {
  props: {
    card: Object,
  },
}

</script>

<style lang="scss" scoped>

</style>