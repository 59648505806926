<template>
  <div v-if="!isLoaded" class="fullscreen-flex-center">
    Loading...
  </div>
  <div v-else-if="isPaused" class="fullscreen-flex-center">
    Press Enter to begin
    <br>
    # words currently unlocked: {{ Object.values(SRS).length }}
    <br>
    <a href="db/focus.php">Edit focus words</a>
  </div>
  <div v-else>
    <div v-if="mode==='quiz'">
      <div id="quiz-term" class="bg-vocab">
        <div lang="ja">
          {{ currentCard.word }}
        </div>
      </div>
      <div id="quiz-response">
        <div :class="{selected: quizMode==='reading'}">{{ readingAnswerKana }}</div>
        <div :class="{selected: quizMode==='meaning'}">{{ meaningAnswer }}</div>
      </div>
    </div>
    <div v-else-if="mode==='learn'" :style="{backgroundColor:previousIncorrect?'#ffafaf':(currentCard.level===0?'antiquewhite':'')}"
    style="width: 100vw; height: 100vh; display: grid; grid-template-rows: auto min-content;">
      <div style="display: grid; grid-template-columns: auto auto auto; gap: 100px; margin: 100px 100px 0; overflow: hidden;">
        <div style="overflow-y: auto;">
          <vocab-card :card="currentCard"></vocab-card>
        </div>
        <div style="overflow-y: auto; display: flex; flex-direction: column; gap: 50px;">
          <div v-for="card in currentKanjiCards">
            <kanji-card :card="card"></kanji-card>
          </div>
        </div>
        <div style="overflow-y: auto; display: flex; flex-direction: column; gap: 50px;">
          <div v-for="card in currentRadicalCards">
            <radical-card :card="card"></radical-card>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; gap: 20px; margin: 50px;">
        <template v-if="previousIncorrect">
          <button @click="saveIncorrectAnswer">Continue</button>
          <button @click="addCustomMeaning" v-if="possibleCustomMeaning">Add {{possibleCustomMeaning}} as Custom Meaning</button>
          <button @click="ignoreIncorrectAnswer">Ignore Incorrect Answer</button>
        </template>
        <template v-else>
          <button @click="finishLearning">Continue</button>
        </template>
      </div>
    </div>
    <div v-else class="fullscreen-flex-center">
      There's nothing left to do right now!  Come back later.
    </div>
  </div>
</template>

<script>
import * as wanakana from 'wanakana';
import VocabCard from './VocabCard';
import KanjiCard from './KanjiCard';
import RadicalCard from './RadicalCard';

export default {
  components: {
    VocabCard,
    KanjiCard,
    RadicalCard,
  },
  data: ()=>({
    // Constants
    DB_SERVER: location.href.includes("natural20design")
        ? "db/"
        : "//localhost:3000/",
    ACCESS_KEY: location.href.includes("natural20design")
        ? prompt("Enter key", localStorage.getItem("n20srskey"))
        : "local",
    SRS_TEMPLATE: {
      review_duration: null,
      answer_duration: null,
      wasMeaningWrong: false,
      wasReadingWrong: false,
    },

    // Dictionaries
    DB: {kanjis: null, radicals: null, vocabs: null, focus: null, customMeanings: null},
    SRS: null,

    // Flags
    isPaused: true, // should be true
    showReadingType: false,
    previousIncorrect: false,
    possibleCustomMeaning: null,

    // State     All should be null for initial state
    mode:            null, // learn, quiz
    quizMode:        null, // meaning, reading
    currentCardKey:  null, // 本, barb, 日本語

    readingAnswer: "",
    meaningAnswer: "",

    // Cache
    start_time: null,
  }),
  computed: {
    isLoaded() {
      return this.DB.kanjis && this.DB.radicals && this.DB.vocabs
      && this.SRS && this.DB.focus && this.DB.customMeanings;
    },
    currentCard() {
      if (!this.isLoaded) return {};
      return this.DB["vocabs"][this.currentCardKey] ?? {};
    },
    currentKanjis() {
      return this.currentCard.kanjis;
    },
    currentKanjiCards() {
      return this.currentKanjis.map(k=>this.DB.kanjis[k]);
    },
    currentRadicals() {
      return [].concat(...this.currentKanjiCards.map(k=>k.radicals));
    },
    currentRadicalCards() {
      return this.currentRadicals.map(r=>this.DB.radicals[r]);
    },
    readingAnswerKana() {
      return wanakana.toHiragana(this.readingAnswer.replaceAll("nn","n"));
    },
    currentSRS() {
      return this.SRS[this.currentCardKey];
    },
  },
  mounted() {
    localStorage.setItem("n20srskey", this.ACCESS_KEY);

    if (!window.addedKeyboardEvent) {
      window.addEventListener('keydown', this.onWindowKeyDown);
      window.addedKeyboardEvent = true;
    }

    Promise.all([
      this.GET_JSON("db_radicals", data=>this.DB.radicals = data),
      this.GET_JSON("db_kanjis", data=>this.DB.kanjis = data),
      this.GET_JSON("db_vocabs", data=>this.DB.vocabs = data),
      this.GET_JSON("srs_vocabs", data=>this.SRS = data),
      this.GET_JSON("focus_words", data=>this.DB.focus = data),
      this.GET_JSON("custom_meanings", data=>this.DB.customMeanings = data),
    ]).then(()=>{
      // All loaded
      const unrecognizedFocusWords = [];
      for (const line of this.DB.focus) {
        const words = line.split(/\s/);
        for (const word of words) {
          let vocab = this.DB.vocabs[word];
          if (!vocab) {
            // Search words via reading
            for (const v of Object.values(this.DB.vocabs)) {
              if (v.reading.filter(r=>r.acceptedAnswer).map(r=>r.reading).includes(word)) {
                vocab = v;
                console.log("Swapping",word,"for",v.key);
                break;
              }
            }
            if (!vocab) {
              unrecognizedFocusWords.push(word);
              continue;
            }
          }
          vocab.level = 0;
          if (vocab.key !== word)
            console.log("Focus vocab " + vocab.key + " ("+word+")");
          else
            console.log("Focus vocab " + word);
        }
      }

      for (const line of this.DB.customMeanings) {
        const [type, key, meaning] = line.split("\t");
        this.DB[type+"s"][key].meanings.push(meaning);
      }

      if (unrecognizedFocusWords.length) {
        //alert("The following focus words are unrecognized:\n"+unrecognizedFocusWords.join("\n"));
        console.log("Unrecognized words", unrecognizedFocusWords);
      }

      // Fill in missing data
      Object.values(this.DB.radicals).map(r=>r.type="radical");
      Object.values(this.DB.kanjis).map(r=>r.type="kanji");
      Object.values(this.DB.vocabs).map(r=>r.type="vocab");

      // Put durations on SRS
      Object.values(this.SRS).map(r=>Object.assign(r, JSON.parse(JSON.stringify(this.SRS_TEMPLATE))));

      // Select the start button
      this.focusInput();

      // Debug
      // this.mode = "learn";
      // this.currentCardKey = "野球";
      // this.isPaused = false;
      // this.quizMode = "reading";
      // this.previousIncorrect = true;
    });
  },
  methods: {
    GET_JSON(resource, then=null) {
      const promise = fetch(this.DB_SERVER+"?action="+resource+"&key="+this.ACCESS_KEY).then(resp=>resp.json());
      if (then) {
        promise.then(then);
      }
      return promise;
    },
    PUT(data) {
      console.log("PUT", data);
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify(data),
      };
      return fetch(this.DB_SERVER+"?action=put&key="+this.ACCESS_KEY, requestOptions)
          .then(response => {
            if (!response.ok)
              throw Error(response.statusText);
            return response.json();
          })
          .catch(err=>alert("fetch error: "+err));
    },
    onWindowKeyDown(ev) {
      if (this.isPaused) {
        if (ev.key === "Enter") {
          this.unpause();
          return;
        }
      }
      else {
        if (this.mode === "quiz") {
          if (["Space"].includes(ev.code) && this.quizMode !== "meaning") {
            this.quizMode = "meaning";
            return;
          }
          if (ev.code === "Enter") {
            if (this.meaningAnswer && this.readingAnswer) {
              this.submitAnswers();
              return;
            }
          }
          if (["Enter", "Tab"].includes(ev.code)) {
            if (this.quizMode !== "meaning")
              this.quizMode = "meaning";
            else
              this.quizMode = "reading";
            ev.preventDefault();
          }
          else if (ev.code === "Enter") {
          }
          else {
            let answer = this.quizMode === "meaning" ? this.meaningAnswer : this.readingAnswer;
            if (ev.key === "Backspace") {
              if (ev.ctrlKey)
                answer = "";
              answer = answer.substr(0, answer.length-1);
            }
            else {
              if (ev.key >= 'a' && ev.key <= 'z' || [" ","-","."].includes(ev.key))
                answer += ev.key;
            }
            if (this.quizMode === "meaning")
              this.meaningAnswer = answer;
            else
              this.readingAnswer = answer;
          }
        }
      }
    },
    saveCardProgress(srs) {
      srs.time = Date.now() / 1000;
      srs.prev_srs_level = srs.srs_level;
      if (srs.wasMeaningWrong || srs.wasReadingWrong)
        srs.srs_level = 0;
      else {
        if (srs.answer_duration < 6000)
          srs.srs_level += 2;
        else if (srs.answer_duration <= 10000)
          srs.srs_level += 1;
        else if (srs.srs_level > 0)
          srs.srs_level -= 1;
      }

      const data = {
        what: "review",
        type: "vocab",
        key: this.currentCardKey,
        prev_srs_level: srs.prev_srs_level,
        srs_level: srs.srs_level,
        answer_duration: srs.answer_duration,
        review_duration: srs.review_duration,
        wasReadingWrong: srs.wasReadingWrong,
        wasMeaningWrong: srs.wasMeaningWrong,
      };

      this.PUT(data).then(resp=>{
        if (!resp.success) alert("Error saving review: "+resp.message);
      });

      // Reset SRS details for this item
      Object.assign(srs, JSON.parse(JSON.stringify(this.SRS_TEMPLATE)));
    },
    submitAnswers() {
      const cc = this.currentCard;
      const srs = this.currentSRS;
      this.meaningAnswer = this.meaningAnswer.toLowerCase();

      // Evaluate meaning
      let meaningCorrect = false;
      for (const meaning of cc.meanings) {
        if (this.isEqualOrLikelyTypo(meaning, this.meaningAnswer))
          meaningCorrect = true;
      }
      if (!meaningCorrect)
        this.possibleCustomMeaning = this.meaningAnswer;

      // Evaluate reading
      const readingCorrect = cc.reading.filter(r=>r.acceptedAnswer).map(r=>r.reading).includes(this.readingAnswerKana);

      if (!meaningCorrect)
        srs.wasMeaningWrong = true;
      if (!readingCorrect)
        srs.wasReadingWrong = true;

      // Update answer statuses
      srs.answer_duration += Date.now() - this.start_time;

      // If it's all correct, then evaluate positively now
      if (meaningCorrect && readingCorrect) {
        this.saveCardProgress(srs);
        this.pullNextCard();
      }
      else {
        this.previousIncorrect = true;
        this.mode = "learn";
        this.focusInput();
      }

      return true;
    },
    unpause() {
      console.log("Unpausing");
      this.isPaused=false;
      if (this.currentCardKey === null)
        this.pullNextCard();
    },
    pullNextCard() {
      this.start_time = Date.now();
      this.showReadingType = false;
      this.previousIncorrect = false;

      const now = Date.now()/1000;
      const reviews = [
        ...Object.entries(this.SRS).map(([key,{srs_level,time}])=>({key, srs_level, time})),
      ]
          .map(r=>{
            r.since = now-(r.time+this.secondsToWaitForSRSLevel(r.srs_level));
            r.daysSince = Math.floor(r.since / (60*60*24));
            r.rand = Math.random();
            return r;
          })
          // Filter out reviews that aren't ready
          .filter(r=>r.since >= 0)
          // Shuffle
          .sort((a,b)=>a.rand - b.rand)
          // Sort by SRS level
          // Nah, sorting by SRS level isn't necessary.  It makes the cards too easy to review.
          // .sort((a,b)=>a.srs_level - b.srs_level)
          // Sort by heavily overdue
          .sort((a,b)=>b.daysSince - a.daysSince)
      ;

      const review = reviews[0];
      if (review) {
        this.meaningAnswer = "";
        this.readingAnswer = "";
        this.quizMode = "reading";
        this.mode = "quiz";
        this.currentCardKey = review.key;
        return;
      }

      // Need to find a lesson, since there are no reviews
      const lessons = [
          ...Object.values(this.DB.vocabs),
      ]
          .map(r=>{
            r.rand = Math.random();
            return r;
          })
          // Remove lessons that are being studied
          .filter(l=>!this.SRS[l.key])
          // Shuffle
          .sort((a,b)=>a.rand - b.rand)
          // Order by level (group by 6 levels)
          .sort((a,b)=>Math.ceil(a.level/6) - Math.ceil(b.level/6))
      ;

      const lesson = lessons[0];
      if (lesson) {
        this.mode = "learn";
        this.currentCardKey = lesson.key;
      }

      this.focusInput();
    },
    focusInput() {
      setTimeout(()=>{
        const input = document.querySelector("input[type=text], button");
        if (input) input.focus();
      }, 10);
    },
    secondsToWaitForSRSLevel(level) {
      return 3600 * Math.pow(2, level-6);
    },
    finishLearning() {

      const isBrandNew = !this.currentSRS;

      if (isBrandNew) {
        this.SRS[this.currentCardKey] = Object.assign({
          srs_level: 0,
          time: Date.now()/1000,
        }, JSON.parse(JSON.stringify(this.SRS_TEMPLATE)));
        // Unlock on the server
        this.PUT({
          what: "review",
          type: "vocab",
          key: this.currentCardKey,
          lesson_duration: Date.now() - this.start_time,
          srs_level: 0,
        }).then(resp=>{
          if (!resp.success) alert("Error unlocking: "+resp.message);
        });
      }
      else {
        this.currentSRS.review_duration += Date.now() - this.start_time;
      }

      this.pullNextCard();

    },
    saveIncorrectAnswer() {
      const srs = this.currentSRS;
      srs.review_duration += Date.now() - this.start_time;
      this.pullNextCard();
    },
    ignoreIncorrectAnswer() {
      const srs = this.currentSRS;
      srs.wasMeaningWrong = false;
      srs.wasReadingWrong = false;
      srs.review_duration += Date.now() - this.start_time;
      this.saveCardProgress(srs);
      this.pullNextCard();
    },
    addCustomMeaning() {
      this.DB["vocabs"][this.currentCardKey].meanings.push(this.possibleCustomMeaning);
      this.PUT({
        what: "customMeaning",
        type: "vocab",
        key: this.currentCardKey,
        meaning: this.possibleCustomMeaning,
      });
      this.ignoreIncorrectAnswer();
    },
    isEqualOrLikelyTypo(foo, bar) {
      foo = foo.toLowerCase().trim();
      bar = bar.toLowerCase().trim();
      foo = foo.replaceAll(/[^a-z]/g,"");
      bar = bar.replaceAll(/[^a-z]/g,"");
      if (foo === bar)
        return true;
      if (Math.abs(foo.length - bar.length) === 1) {
        // Try removing one character from each
        for (let i = 0; i < Math.max(foo.length, bar.length); i++) {
          if (foo.slice(0, i) + foo.slice(i+1) === bar)
            return true;
          if (bar.slice(0, i) + bar.slice(i+1) === foo)
            return true;
        }
      }
      if (foo.length === bar.length) {
        // Anagram
        if (foo.split("").sort((a,b)=>a.localeCompare(b)).join("") === bar.split("").sort((a,b)=>a.localeCompare(b)).join(""))
          return true;
        // Maybe just one character is different
        for (let i = 0; i < foo.length; i++) {
          if (foo.slice(0, i) + foo.slice(i+1) === bar.slice(0, i) + bar.slice(i+1))
            return true;
        }
      }
      return false;
    },
  },
  updated() {
    const audios = document.querySelectorAll("audio");
    for (let i = 0; i < audios.length; i++) {
      setTimeout(()=>audios[i].play(), i*3000);
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  color: #333;
}
html, body, #app {
  min-height: 100% !important;
  height: 100%;
}
body {
  margin: 0;
  background-color: #eee;
  min-height: 100vh;
}
#app {
  min-height: 100vh;
  position: relative;
}
#quiz-term {
  font-size:200px;
  display: flex;
  align-items: center;
  justify-content: center;
  div { font-size: 200px; margin-bottom: 15px; color: white; }
  &.radical {
    img { padding-top: 30px;}
    background-color: #00a1f1;
  }
  &.kanji { background-color: #f100a1; }
  &.vocab { background-color: #a100f1; }
}
#quiz-prompt {
  text-transform: capitalize;
  text-align: center;
  font-size: 30px;
  padding: 15px 0;
  &.meaning {
    &, &>* { color: #333; }
  }
  &.reading {
    background-color: #333;
    &, &>* {color: #fff;}
  }
}
#quiz-response {
  div {
    width: 100%;
    font-size: 50px;
    text-align: center;
    padding: 20px 0;
    background-color: white;
    box-sizing: content-box;
    height: 68px;
    border: 1px solid #ccc;
    &.selected {
      ///border: 10px solid black;
      box-shadow: inset #cafe10 0 0 20px 0px;
    }
  }
}
.fullscreen-flex-center {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.learn-symbol {
  border-radius: 15px;
  font-size: 50px;
  min-width: 100px;
  white-space: nowrap;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &>div {
    padding: 20px;
  }
  &>*, & {
    color: white;
  }
  &.radical {
    img { height: 50px;}
    background-color: #00a1f1;
  }
  &.kanji { background-color: #f100a1; }
  &.vocab { background-color: #a100f1; }
  &.small {
    height: 50px;
    min-width: 50px;
    padding: 10px;
    border-radius: 7px;
    font-size: 25px;
    img {height: 25px; }
  }
}
.bg-radical { background-color: #00a1f1; }
.bg-kanji { background-color: #f100a1; }
.bg-vocab { background-color: #a100f1; }
.learn-key {
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 50px;
  &.small {
    font-size: 25px;
  }
}
button {
  outline-offset: 4px;
  outline-color: #ccc;
}
.learn-row {
  display: flex; justify-content: center; align-items: center;
}
.learn-alternate-meanings {
  position: relative;
  left: 10px;
  top: 11px;
  font-size: 20px;
  &.small {
    font-size: 15px;
  }
}
svg {
  height: 1.5em;
}
#pattern-table {
  width: 100%;
  th {
    vertical-align: top;
    text-align: left;
  }
}
audio {
  width: 100px;
}
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display,
{
  display: none;
}



/* Copied from WaniKani */
[class*="-highlight"] {cursor: help;padding: 1px 4px;color: #fff;text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);white-space: nowrap;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;-webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset;-moz-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset;box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset}
.kanji-highlight {background-color: #f100a1;background-image: -moz-linear-gradient(top, #f0a, #dd0093);background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f0a), to(#dd0093));background-image: -webkit-linear-gradient(top, #f0a, #dd0093);background-image: -o-linear-gradient(top, #f0a, #dd0093);background-image: linear-gradient(to bottom, #f0a, #dd0093);background-repeat: repeat-x;filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFF00AA', endColorstr='#FFDD0093', GradientType=0)}
.radical-highlight {background-color: #00a1f1;background-image: -moz-linear-gradient(top, #0af, #0093dd);background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0af), to(#0093dd));background-image: -webkit-linear-gradient(top, #0af, #0093dd);background-image: -o-linear-gradient(top, #0af, #0093dd);background-image: linear-gradient(to bottom, #0af, #0093dd);background-repeat: repeat-x;filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF00AAFF', endColorstr='#FF0093DD', GradientType=0)}
.vocabulary-highlight {background-color: #a100f1;background-image: -moz-linear-gradient(top, #a0f, #9300dd);background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#a0f), to(#9300dd));background-image: -webkit-linear-gradient(top, #a0f, #9300dd);background-image: -o-linear-gradient(top, #a0f, #9300dd);background-image: linear-gradient(to bottom, #a0f, #9300dd);background-repeat: repeat-x;filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFAA00FF', endColorstr='#FF9300DD', GradientType=0)}
.reading-highlight {background-color: #474747;background-image: -moz-linear-gradient(top, #555, #333);background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#555), to(#333));background-image: -webkit-linear-gradient(top, #555, #333);background-image: -o-linear-gradient(top, #555, #333);background-image: linear-gradient(to bottom, #555, #333);background-repeat: repeat-x;filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF555555', endColorstr='#FF333333', GradientType=0);-webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.8) inset;-moz-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.8) inset;box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.8) inset}
.meaning-highlight {background-color: #cccccc;background-image: -moz-linear-gradient(top, #eee, #999);background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eee), to(#999));background-image: -webkit-linear-gradient(top, #eee, #999);background-image: -o-linear-gradient(top, #eee, #999);background-image: linear-gradient(to bottom, #eee, #999);background-repeat: repeat-x;filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FF999999', GradientType=0)}

</style>
